import { load, loadMessages } from "@progress/kendo-react-intl";
import i18n from "i18next";
import {debounce} from 'lodash';

export enum languageEnum {
    ENGLISH = "en",
    FRENCH = "fr",
    MAROC = "ma",
}

export enum StructureTypeEnum {
    STRUCTURE_TYPE_TERRITORY = "Territory",
    STRUCTURE_TYPE_COMPANY = "Company",
    STRUCTURE_TYPE_AGENCY = "Agency",
    STRUCTURE_TYPE_PRODUCTIONSITE = "ProductionSite"
}

export class Utilities {
    static downloadFile(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const headerval = response.headers['content-disposition'];
        var filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    static downloadLayer(response, displayName) {
        const blob = new Blob([JSON.stringify(response)], { type: "application/json" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = displayName;
        link.click();  
    }

    static getLocale(): languageEnum {
        return i18n.language.startsWith(languageEnum.FRENCH) ? languageEnum.FRENCH : languageEnum.ENGLISH;
    }

    static loadKendoTranslations(...messages: any[]) {
        load(...messages);

        const locale = this.getLocale();
        if (locale === languageEnum.FRENCH) {
            // à faire évoluer selon les langues
            const overridenMessages = require('src/assets/locales/fr/kendo.json');
            loadMessages(overridenMessages, locale);
        }
    }

    static anySignal(signals: AbortSignal[]) {
        const controller = new AbortController();
        const unsubscribe: (() => void)[] = [];

        function onAbort(signal: AbortSignal) {
            controller.abort();
            unsubscribe.forEach((f) => f());
        }

        for (const signal of signals) {
            if (signal.aborted) {
                onAbort(signal);
                break;
            }
            const handler = onAbort.bind(undefined, signal);
            signal.addEventListener('abort', handler);
            unsubscribe.push(() => signal.removeEventListener('abort', handler));
        }

        return controller.signal;
    }

    static joinNotEmpty(data: any[], join: string = " "): string {
        if (!data)
            return "";

        return data
            .filter(x => !!x)
            .map(x => `${x}`)
            .reduce((acc, x) => {
                    if (acc !== "") {
                        acc += join;
                    }
                    acc += x;
                    return acc;
                }, "");
    }

    static formatNumber(value: string | number, maximumFractionDigits = 2): string {
        const numberValue = typeof (value) === "number" ? value : Number(value);
        return isNaN(numberValue)
            ? `${value}`
            : new Intl.NumberFormat(Utilities.getLocale(), { maximumFractionDigits }).format(numberValue);
    }

    // formatUnit(meters, [1, "m"], [1000, "km"]);
    static formatWithUnit(value: number, ...units: [/*factor*/number, /*unit*/string][]): string {
        const reverseSorted = units.sort(([leftFactor, leftUnit], [rightFactor, rightUnit]) => rightFactor - leftFactor);
        let defaultUnit: string = null;
        for (let [factor, unit] of reverseSorted) {
            if (Math.abs(value) >= factor) {
                return `${Utilities.formatNumber(value / factor)} ${unit}`;
            }

            if (factor === 1) {
                defaultUnit = unit;
            }
        }

        let result = `${Utilities.formatNumber(value)}`;
        if (defaultUnit) {
            result += ` ${defaultUnit}`;
        }

        return result;
    }

    static formatMetersKilometers(meters: number) {
        return Utilities.formatWithUnit(meters, [1, "m"], [1000, "km"]);
    }

    static hexToRgb(hex) {    
        hex = hex.replace("#", "").toLowerCase();

        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        if (hex.length === 3) {
            hex = hex.replace(/(.)/g, "$1$1");
        }

        // Verify if the input is a valid hexadecimal color code
        const validHexRegex = /^[a-f0-9]{6}$/;
        if (!validHexRegex.test(hex)) {
            const error = `Invalid Hexadecimal Color Code '${hex}'.`;
            throw new Error(error);
        }

        // Convert the hex value to decimal
        const [r, g, b] = hex.match(/[a-f0-9]{2}/g).map((value) => parseInt(value, 16));

        // Return the RGB values as an object
        return {
            r,
            g,
            b
        };
    }

    static rgbToHex({r, g, b}: {r: number, g: number, b: number}, rgbBase: 1 | 255 = 255) {    
        if (this.isNullOrUndefined(r) || this.isNullOrUndefined(g) || this.isNullOrUndefined(b))
            return null;
        const coeff = rgbBase === 255 ? 1 : 255;
        const hex = '#' + [r, g, b].map(x => `0${Math.round(x * coeff).toString(16)}`.slice(-2)).join('');
        return hex;
    }

    static isNullOrUndefined = (x: any) => x === undefined || x === null;
    
    static isLink(text: string): boolean {
        if(!text || text == '')
            return false;
        
        try{
            const url = new URL(text);
            return true;
        }
        catch{
            return false;
        }
    }
}

export function mergedDebounce<T, U>(callback: (obj: T) => void,
                              delay: number,
                              accFactory: () => T,
                              merger: (acc: T, obj: U) => T) {
    let merged = accFactory();
    const debounced = debounce(() => {
        callback(merged);
        merged = accFactory();
    }, delay);

    return (obj: U) => {
        merged = merger(merged, obj);
        debounced(merged);
    };
}

export function listMergeDebounce<T>(callback: (acc: T[]) => void, delay: number){
    return mergedDebounce(callback, delay,
        () => [], (acc, obj: T) => { acc.push(obj); return acc; });
}