import React, {createContext, ReactNode, useContext, useState} from 'react';
import {LayersPreferences} from "../model/LayersPreferences";
import axios from "axios";
import {UserAuthorizedLightModel} from "../model/UserAuthorizedLightModel";
import {mergedDebounce} from "./utils";

type UserPreferencesContext = {
    language : string,
    setLanguage: (language: string) => void,
    activeFilters : string[],
    setActiveFilters: (filters: string[]) => void,
    layersConfig : LayersPreferences[],
    setSelectedLayers: (layers: string[]) => void,
    updateLayerOpacity: (layer: string, opacity: number) => void
}

const UserPreferencesContext = createContext<UserPreferencesContext>(null);
export const useUserPreferences = () => useContext(UserPreferencesContext);

const debouncedUpdateUserLayerOpacity = mergedDebounce((acc) => {
        acc.forEach((values, layer) => {
            const opacity = values.shift();
            UserPrefService.SetLayerOpacity(layer, opacity).catch(console.error);
        });
    }, 300,
    () => new Map<string, number[]>(),
    (acc, obj:{layer:string, opacity: number}) => {
        let layerOpacities = acc.get(obj.layer);
        if(!layerOpacities){
            layerOpacities = [];
            acc.set(obj.layer, layerOpacities);
        }
        layerOpacities.unshift(obj.opacity);
        return acc;
    });

export const UserPreferencesContextProvider = ({user, children} : {user:UserAuthorizedLightModel, children: ReactNode}) => {
    const [language, setLanguage] = useState<string>(user.preferences.language);
    const [activeFilters, setActiveFilters] = useState<string[]>(user.preferences.activeFilters);
    const [layersConfig, setLayersConfig] = useState<LayersPreferences[]>(user.preferences.layers);
    
    const updateLanguage = (lng) =>{
        setLanguage(lng);
        UserPrefService.SetUserLanguage(lng)
            .then(() =>{
                window.location.reload();
            });
    };
    
    const updateSelectedLayers = (layers: string[]) => {
        setLayersConfig(userLayers => {
            const result:LayersPreferences[] = []
            const r = new Map<string,LayersPreferences>(userLayers.map(x =>[x.layerName, x]))
            
            layers.forEach((selectedLayer, index) => {
                const userLayer = r.get(selectedLayer);
                if(userLayer){
                    userLayer.active = true;
                    userLayer.order = index;
                    result.push(userLayer);
                    
                    r.delete(selectedLayer);
                }else{
                    result.push({
                        layerName: selectedLayer,
                        opacity: 0.5,
                        active: true,
                        order: index
                    })
                }
            });
            
            r.forEach(userLayer => {
                userLayer.active = false;
                userLayer.order = -1;
                result.push(userLayer);
            });
            
            UserPrefService.SetUserSelectedLayers(layers).catch(console.error);
            return result;
        });
    }
    
    const updateActiveFilters = (filters: string[]) => {
        setActiveFilters(filters);
        UserPrefService.SetUserFilters(filters);
    };
    
    const updateLayerOpacity = (layer: string, opacity: number) => {
        setLayersConfig(x => {
            const configs = [...x]
            let layerConfig = configs.find(x => x.layerName == layer);
            if(!layerConfig){
                // on ne devrait pas se trouver dans ce cas
                // car pour changer l'opacité il faut que la couche soit déjà activée
                
                console.error(`Cannot find layer ${layer} to set opacity.`)
                return configs;
            }

            layerConfig.opacity = opacity;
            debouncedUpdateUserLayerOpacity({layer, opacity});
            return configs;
        });
    }
    
    const userContext: UserPreferencesContext = {
        language,
        setLanguage : updateLanguage,
        activeFilters,
        setActiveFilters : updateActiveFilters,
        layersConfig,
        setSelectedLayers: updateSelectedLayers,
        updateLayerOpacity
    }
    
    return <UserPreferencesContext.Provider value={userContext}>
        {children}
    </UserPreferencesContext.Provider>
}

class UserPrefService {

    public static GetUserLanguage = async (username: string): Promise<string> => {
        const response = await axios.get('api/user/get-user-language', { params: { username } });
        return response.data;
    }

    public static SetUserLanguage = async (language: string): Promise<any> => {
        const response = await axios.post('api/user/set-user-language', {
            language
        });
        return response.data;
    }

    public static SetUserFilters = async (filters: string[]): Promise<any> => {
        const response = await axios.post('api/user/set-user-filters', {
            activeFilters : filters
        });
        return response.data;
    }

    public static SetUserSelectedLayers = async (layerNames: string[]): Promise<any> => {
        const response = await axios.post('api/user/set-user-selected-layers', {
            layers : layerNames.map(layer => ({
                layerName : layer
            }))
        });
        return response.data;
    }

    public static SetLayerOpacity = async (layerName: string, opacity: number): Promise<any> => {
        const response = await axios.post('api/user/set-layer-opacity', {
            layerName,
            opacity
        });
        return response.data;
    }
}